var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Moment from 'react-moment';
import Attachment from './Attachment';
import Delete from '@mui/icons-material/Delete';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import { useTranslate } from '../../../translation/Translate';
import SendAttachmentToHelpChatModal from './SendAttachmentToHelpChatModal';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import useToast from '../../../../hooks/useToast';
var PeriodMessage = function (_a) {
    var message = _a.message, time = _a.time, therapist = _a.therapist, attachments = _a.attachments, periodId = _a.periodId, replyToMessage = _a.replyToMessage, sender = _a.sender, readBy = _a.readBy, setOpenDeleteConfirmation = _a.setOpenDeleteConfirmation, setSelectedMessage = _a.setSelectedMessage, readonly = _a.readonly, client = _a.client, newest = _a.newest;
    var t = useTranslate();
    var _b = __read(useState(false), 2), openSendAttachmentToHelpChatModal = _b[0], setOpenSendAttachmentToHelpChatModal = _b[1];
    var messageClass = readBy
        ? 'px-3 py-2 bg-white rounded-2xl mt-1 border border-cyan max-w-xs sm:max-w-sm md:max-w-md xl:max-w-lg 2xl:max-w-xl w-full'
        : 'px-3 py-2 bg-white rounded-2xl mt-1 border-2 border-warning-200 max-w-xs sm:max-w-sm md:max-w-md xl:max-w-lg 2xl:max-w-xl w-full';
    var messageLeft = ' rounded-br-none place-self-start';
    var messageRight = ' rounded-bl-none place-self-end';
    messageClass += therapist ? messageRight : messageLeft;
    var dateClass = 'text-sm text-gray-500' + therapist ? ' text-right' : ' text-left';
    var showToastMessage = useToast().showToastMessage;
    var messageAsRows = message.message ? message.message.split('\n') : [];
    function formatMessage(message) {
        return message.split(/\s/g).map(function (token) {
            switch (true) {
                case token.startsWith('http://') || token.startsWith('https://'):
                    return (_jsxs(_Fragment, { children: [_jsx("a", __assign({ href: token, className: "underline" }, { children: token })), ' '] }));
                default:
                    return token + ' ';
            }
        });
    }
    var showAttachments = attachments && attachments.length > 0 && !message.deleted;
    return (_jsxs("div", __assign({ className: "my-1 grid" }, { children: [_jsx(ToastContainer, { limit: 1 }), _jsx("div", __assign({ className: dateClass }, { children: therapist === true ? (_jsx(Moment, __assign({ format: t("period.message_date_format") }, { children: time }))) : (_jsxs(_Fragment, { children: [sender, " ", _jsx(Moment, __assign({ format: t("period.message_date_format") }, { children: time }))] })) })), _jsxs("div", __assign({ className: messageClass }, { children: [therapist === true ? (_jsx("div", __assign({ className: "relative" }, { children: message.deleted ? (_jsx("div", __assign({ className: "flex justify-end" }, { children: _jsx("p", __assign({ style: {
                                    maxWidth: '100%',
                                    overflowWrap: 'break-word',
                                    wordWrap: 'break-word',
                                    hyphens: 'auto',
                                } }, { children: t("period.message_deleted") })) }))) : (_jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("p", __assign({ style: {
                                        maxWidth: '100%',
                                        overflowWrap: 'break-word',
                                        wordWrap: 'break-word',
                                        hyphens: 'auto',
                                    } }, { children: messageAsRows.map(function (paragraph, index) {
                                        var isLastRow = index === messageAsRows.length - 1;
                                        return (_jsxs(React.Fragment, { children: [formatMessage(paragraph), message.message && !isLastRow && _jsx("br", {})] }, index));
                                    }) })), setOpenDeleteConfirmation &&
                                    setSelectedMessage &&
                                    !message.deleted && !readonly && (_jsx("div", __assign({ onClick: function () {
                                        setOpenDeleteConfirmation(true),
                                            setSelectedMessage(message); // eslint-disable-line prettier/prettier
                                    }, className: "flex justify-end cursor-pointer" }, { children: _jsx(Delete, {}) })))] }))) }))) : (_jsxs("div", __assign({ className: "relative" }, { children: [_jsx("p", __assign({ style: {
                                    maxWidth: '100%',
                                    overflowWrap: 'break-word',
                                    wordWrap: 'break-word',
                                    hyphens: 'auto',
                                } }, { children: message.deleted ? (_jsx(_Fragment, { children: t("period.message_deleted") })) : (_jsx(_Fragment, { children: messageAsRows.map(function (paragraph, index) {
                                        var isLastRow = index === messageAsRows.length - 1;
                                        return (_jsxs(React.Fragment, { children: [formatMessage(paragraph), message.message && !isLastRow && _jsx("br", {})] }, index));
                                    }) })) })), !client && attachments && attachments.length > 0 && !message.deleted && (_jsx("div", __assign({ onClick: function () { return setOpenSendAttachmentToHelpChatModal(true); }, className: "flex items-center" }, { children: _jsx(ShortcutIcon, { style: { cursor: 'pointer' } }) })))] }))), showAttachments && (_jsx("div", __assign({ className: message.message === '' ? '' : 'mt-2 pt-2 border-t' }, { children: _jsx("div", __assign({ className: "flex flex-col items-center justify-center gap-2" }, { children: attachments.map(function (a, index) {
                                return a !== null && a.file && (_jsx(Attachment, { preload: newest, periodId: periodId, attachment: a }, JSON.stringify(a)));
                            }) })) })))] })), !client &&
                _jsx(SendAttachmentToHelpChatModal, { opentConfirmationModal: openSendAttachmentToHelpChatModal, setOpenConfirmationModal: setOpenSendAttachmentToHelpChatModal, periodId: periodId, message: message, showToastMessage: showToastMessage })] })));
};
export default PeriodMessage;
