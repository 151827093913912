var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePeriodMessage, updatePeriodSessions, getAndSetReadByMessagesActivePeriods, } from '../../../../actions/periodActions';
import { useTranslate } from '../../../translation/Translate';
import { setPeriod } from '../../../../reducers/periodSlice';
import ConfirmationModal from '../../modals/ConfirmationModal';
import PeriodMessage from './PeriodMessage';
import PeriodDivider from './PeriodDivider';
import useUser from '../../../../hooks/useUser';
var PeriodMessages = function (_a) {
    var messages = _a.messages, client = _a.client, periodId = _a.periodId, periodValidUntil = _a.periodValidUntil, replyToMessage = _a.replyToMessage, sessionsDone = _a.sessionsDone, sessions = _a.sessions, readonly = _a.readonly;
    var t = useTranslate();
    var dispatch = useDispatch();
    var user = useUser();
    var chatEnd = useRef(null);
    var userinfo = useSelector(function (state) { return state.user.userinfo; });
    var _b = __read(React.useState(false), 2), openConfirmation = _b[0], setOpenConfirmation = _b[1];
    var _c = __read(React.useState(false), 2), openDeleteConfirmation = _c[0], setOpenDeleteConfirmation = _c[1];
    var _d = __read(React.useState(null), 2), selectedMessage = _d[0], setSelectedMessage = _d[1];
    var hasUnreadMessages = function () {
        var _a;
        var userId = (_a = userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId) !== null && _a !== void 0 ? _a : '';
        return messages.some(function (msg) { return !(msg.createdBy === userId) && !msg.readBy.some(function (log) { return log.user === userId; }); });
    };
    var _e = __read(React.useState(hasUnreadMessages()), 2), showMarkRead = _e[0], setShowMarkRead = _e[1];
    var markSessionCompletedConfirmation = t('period.mark_session_completed_confirmation');
    var deleteMessageConfirmationTranslate = t('period.delete_message_confirmation');
    React.useEffect(function () {
        var _a;
        (_a = chatEnd.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView(true);
    }, [messages, chatEnd]);
    var handleSessionDone = function () {
        if (Number(sessionsDone) < 12) {
            updatePeriodSessions({ periodId: periodId, sessionsDone: Number(sessionsDone) }, user.session)
                .then(function (data) {
                if (data && data.sessions) {
                    dispatch(setPeriod(data));
                    setOpenConfirmation(false);
                }
            });
        }
    };
    var deleteMessageFunction = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedMessage) return [3 /*break*/, 2];
                    return [4 /*yield*/, deletePeriodMessage(periodId, selectedMessage, user.session)];
                case 1:
                    data = _a.sent();
                    if (data) {
                        setOpenDeleteConfirmation(false);
                        dispatch(setPeriod(data));
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var setAllMessagesRead = function () { return __awaiter(void 0, void 0, void 0, function () {
        var userId, _a, updatedPeriod, _b;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    userId = (_c = userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId) !== null && _c !== void 0 ? _c : '';
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getAndSetReadByMessagesActivePeriods(periodId, userId, user.session)];
                case 2:
                    _a = __read.apply(void 0, [_d.sent(), 1]), updatedPeriod = _a[0];
                    dispatch(setPeriod(updatedPeriod));
                    setShowMarkRead(false);
                    return [3 /*break*/, 4];
                case 3:
                    _b = _d.sent();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: "relative bg-lightBlue rounded-md border border-lightBlue" }, { children: [_jsxs("div", __assign({ style: { height: '50vh' }, className: "overflow-y-scroll overflow-x-hidden py-2 md:py-4 px-1 sm:px-3 md:px-8" }, { children: [messages.length > 0 ? (messages.map(function (message, index) {
                        var isMessageCreatedByUser = message.createdBy === (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId);
                        var isMessageReadByUser = message.readBy.find(function (stamp) { return stamp.user === (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId); });
                        var isReadByUser = Boolean(isMessageCreatedByUser || isMessageReadByUser);
                        return (_jsx(PeriodMessage, { message: message, time: message.created, therapist: message.createdBy === (userinfo === null || userinfo === void 0 ? void 0 : userinfo.userId), periodId: periodId, attachments: message.attachments, replyToMessage: replyToMessage, sender: message.senderName, readBy: isReadByUser, setOpenDeleteConfirmation: setOpenDeleteConfirmation, setSelectedMessage: setSelectedMessage, readonly: readonly, client: client }, message.createdBy + "-" + message.created.toString()));
                    })) : (_jsx("div", __assign({ className: "text-center p-6" }, { children: t('messages.no_messages') }))), _jsx("div", { id: "newest", ref: chatEnd }), _jsx(PeriodDivider, { type: "completedSession", text: "".concat(sessionsDone, "/").concat(sessions, " ") + t('period.sessions_completed_end') }), periodValidUntil && (_jsx("div", __assign({ className: "flex flex-row justify-center items-center" }, { children: _jsx("span", { children: "Jakso voimassa ".concat(new Date(periodValidUntil).toLocaleDateString('fi'), " asti") }) }))), client ? ('') : (_jsx(PeriodDivider, { type: "completeSession", text: t('period.mark_session_completed'), action: function () { return setOpenConfirmation(!openConfirmation); } })), showMarkRead && (_jsx(PeriodDivider, { type: "message", text: t('messages.mark_read'), action: setAllMessagesRead })), _jsx("div", { ref: chatEnd, id: "newest" })] })), _jsx(ConfirmationModal, { headerText: markSessionCompletedConfirmation, opentConfirmationModal: openConfirmation, setOpenConfirmationModal: setOpenConfirmation, actionFunction: handleSessionDone }), _jsx(ConfirmationModal, { headerText: deleteMessageConfirmationTranslate, opentConfirmationModal: openDeleteConfirmation, setOpenConfirmationModal: setOpenDeleteConfirmation, actionFunction: deleteMessageFunction })] })));
};
export default PeriodMessages;
